import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Edit, Settings, CheckCircle } from 'react-feather';
import { Link } from '@reach/router';
import { Loader } from '@axeedge/go-teacher-components';


import Classes from './scenes/Classes';
import Staff from './scenes/Staff';
import Purchases from './components/Purchases';
import SaveConfirm from '../../../../components/SaveConfirm';
import { AuthContext } from '../../../../services/user/AuthProvider';
import { GET_SCHOOL_QUERY, MERGE_PUPIL_DUPLICATES } from '../../../../services/school/graphql';
import { GET_CONTEST } from '../../../WritingBees/services/graphql';
import styles from './SchoolHome.module.scss';
import BackLink from '../../../../components/BackLink';
import BackButton from '../../../../components/BackButton';
import AddToBee from '../../../WritingBees/AddToBee';

import cx from 'classnames';
import moment from 'moment';
import { Calendar } from 'react-feather';

const CLASSES = 1;
const STAFF = 2;
const ADD_TO_BEE = 3;

const SchoolHome = ({ schoolId }) => {

    const { currentUser } = useContext(AuthContext);

    const [subView, setSubView] = useState(0);
    const [schoolStats, setSchoolStats] = useState(null);
    const [formErrors, setFormErrors] = useState(null);
    const [mergeDone, setMergeDone] = useState(false);

    const { data, error, loading } = useQuery(GET_SCHOOL_QUERY, {
        variables: {
            schoolId
        },
        fetchPolicy: 'network-only'
    });

    const [getContest, { data: contestData, loading: contestLoading, error: contestError }] = useLazyQuery(GET_CONTEST, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (data && data.school && data.school.stats) {
            setSchoolStats(data.school.stats);
        }
    }, [data, setSchoolStats]);

    const getStats = type => {
        const item = schoolStats.find(s => s.key.indexOf(type) !== -1);
        if (item) {
            return item.value;
        }
    }


    const [mergeDuplicates, { loading: merging }] = useMutation(MERGE_PUPIL_DUPLICATES, {
        update: (_, { data }) => {
            if (data.mergeDuplicates.errors && data.mergeDuplicates.errors.length !== 0) {
                setFormErrors(data.mergeDuplicates.errors[0]);
                return;
            }
            if (data.mergeDuplicates && data.mergeDuplicates.school && data.mergeDuplicates.school.id) {
                setMergeDone(true);
            }
        },
        refetchQueries: [{ query: GET_SCHOOL_QUERY, variables: { schoolId } }]
    })

    useEffect(() => {
        getContest()
    }, [])

    let inWritingBee = data && contestData && data.school.contests.filter(c => c.id === contestData.activeContest.id).length > 0;

    if (error) {
        return <p>{error.message}</p>;
    }

    if (loading) {
        return <Loader />
    }

    let sub;

    if (data && data.school && schoolStats && contestData) {

        const { schoolSubscriptions: subscriptions } = data.school;
        const readSubscriptions = subscriptions.filter(s => s.app && s.app.name === 'BoomReader');
        const orderedSubs = [...readSubscriptions].sort((a, b) => a.expiresAt.localeCompare(b.expiresAt));
        sub = ([...orderedSubs].pop())


        return (
            <div className={styles.container}>
                {
                    !subView ? (
                        <Fragment>
                            {currentUser?.countryAdmin === 'IND' ?
                                <BackLink to='/' text='Home' /> :
                                <BackLink to='/schools' text='Schools' />
                            }
                            <div className={styles.header}>
                                <h1 className="u-m-base-2">
                                    {data.school.name}
                                    <span className='body-text u-m-left-2'>School code: {data.school.schoolCode}</span>
                                </h1>
                                <span>
                                    {inWritingBee && <span className='label label-bee u-m-right-2'><CheckCircle size="18" /> Bee</span>}
                                    <Link to='edit'><Settings className={styles.headerIcon} /></Link>
                                </span>
                            </div>
                            <div className='u-display-flex u-m-base-2 u-m-top-2'>
                                <div>
                                    <p>{data.school.address}  {data.school.address2}  {data.school.city} &dash;  {data.school.zip}</p>
                                    <p>Contact: {data.school.admins[0]?.displayName} {data.school.admins[0]?.email} {data.school.schoolPhoneNumber}</p>
                                </div>
                                <div className='u-m-left-auto'>
                                    {data.school.canAccessWonde && <span className='u-m-left-2 light body-text label label-muted'>Wonde</span>}
                                    {!data.school.goReadSubscriptionExpired && sub && sub.isTrial && <><span className='u-m-left-2 light body-text label label-muted'>BoomReader Trial Subscription</span></>}
                                </div>
                            </div>


                            {inWritingBee && (
                                <div className={styles.panel}>
                                    <h2>{data.school.contests[0].name}</h2>
                                    <p className='u-bold u-display-flex u-align-center'>Students: {data.school.contestMemberships[0].actualStudentsCounter} / {data.school.contestMemberships[0].studentsCounter}
                                        <button className='btn-reset u-m-left-2' onClick={() => ''}><Edit /></button>
                                    </p>
                                    <p className='u-bold'>{data.school.contestMemberships[0].studentsClass.name}</p>
                                </div>
                            )}
                            {currentUser?.countryAdmin !== 'IND' &&
                                <>
                                    <h1 className={cx(styles.sectionTitle, 'u-m-top-3')}>Usage Stats</h1>
                                    <div className={styles.smallStats}>
                                        <div className={styles.smallStat}>
                                            <p className={styles.smallStatTitle}>Total Pupils</p>
                                            <div className={styles.smallStatCount}>{getStats('students')}</div>
                                            {data.school.duplicates > 0 &&
                                                <p className={cx(styles.smallStatTitle, 'u-m-base-0')}>
                                                    {data.school.duplicates} {data.school.duplicates === 1 ? 'duplicate' : 'duplicates'}
                                                    <button
                                                        className='btn-reset link-underline u-m-left-1'
                                                        disabled={merging}
                                                        onClick={() => mergeDuplicates({
                                                            variables: {
                                                                schoolId: data.school.id
                                                            }
                                                        })}>{merging ? 'Saving...' : 'merge'}</button>
                                                </p>
                                            }
                                            {formErrors && <p>{formErrors}</p>}
                                            {mergeDone && <SaveConfirm message={'Merge done'} hideConfirm={() => setMergeDone(false)} />}
                                        </div>
                                        <div className={styles.smallStat}>
                                            <p className={styles.smallStatTitle}>Total Parents</p>
                                            <div className={styles.smallStatCount}>{getStats('parents')}</div>
                                        </div>
                                        <div className={styles.smallStat}>
                                            <p className={styles.smallStatTitle}>Active Parents</p>
                                            <div className={styles.smallStatCount}>{getStats('active_parents')}</div>
                                        </div>
                                    </div>

                                    <div className={styles.smallStats}>
                                        <div className={styles.smallStat}>
                                            <p className={styles.smallStatTitle}>Students with Parents</p>
                                            <div className={styles.smallStatCount}>{getStats('students_with_parents_counter')}</div>
                                            <div className={styles.smallStatPerc}>{getStats('students_with_parents_percentage')}%</div>
                                        </div>
                                        <div className={styles.smallStat}>
                                            <p className={styles.smallStatTitle}>Students with Parent Logs in prev 7 days</p>
                                            <div className={styles.smallStatCount}>{getStats('students_with_entries_by_parents')}</div>
                                            <div className={styles.smallStatPerc}>{getStats('students_with_entries_by_parents_percentage')}%</div>
                                        </div>
                                    </div>

                                </>
                            }
                            <h1 className={cx(styles.sectionTitle, 'u-m-top-3')}>Utilites</h1>

                            <div className={styles.largeStats}>
                                <div onClick={() => setSubView(CLASSES)} className={styles.largeStat}>
                                    <p className={styles.largeStatTitle}>Classes</p>
                                    <p className={styles.largeStatCount}>{(data.school.classes && data.school.classes.length) || 0}</p>
                                </div>
                                <div onClick={() => setSubView(STAFF)} className={styles.largeStat}>
                                    <p className={styles.largeStatTitle}>Staff</p>
                                    <p className={styles.largeStatCount}>{data.school.teachers.length}</p>
                                </div>
                            </div>
                            <Purchases
                                setSubView={setSubView}
                                currentUser={currentUser}
                                schoolId={data.school.id}
                                studentsUsingGoRead={data.school.studentsUsingGoRead}
                                subscriptions={data.school.schoolSubscriptions}
                                invoiceRequests={data.school.invoiceRequests}
                                inWritingBee={inWritingBee}
                            />
                        </Fragment>
                    ) : (
                        <div className="site-admin__sub-view">
                            <BackButton
                                onClick={() => setSubView(null)}
                                text={data.school.name}
                            />
                            {subView === CLASSES && <Classes school={data.school} />}
                            {subView === STAFF && <Staff currentUser={currentUser} school={data.school} />}
                            {subView === ADD_TO_BEE && <AddToBee close={() => setSubView(null)} school={data.school} />}
                        </div>
                    )
                }

            { data?.school?.invoiceRequests?.length > 0 && 

             <div>
                <h2>Invoices</h2>
                <table className={styles.invoiceTable}>
                    <thead>
                        <tr>
                            <th className={styles.issuedAt}>Issued at</th>
                            <th className={styles.amount}>Amount</th>
                            <th className={styles.linkToInvoice}></th>
                        </tr>
                    </thead>
                    <tbody>
                    {data?.school?.invoiceRequests.map((invoiceRequest) => {
                        return <tr key={invoiceRequest.id}>
                            <td className={styles.issuedAt}>
                                {
                                    invoiceRequest.issuedAt ? <div className={styles.issuedAtData}><Calendar className='u-m-right-1' />{moment(invoiceRequest.issuedAt).format('DD MMM YYYY')}</div> : '-'
                                }
                            </td>
                            <td className={styles.amount}>£{invoiceRequest.amount}</td>
                            <td className={styles.linkToInvoice}>
                                <Link
                                    className={styles.link}
                                    to={`/invoices/${invoiceRequest.id}`}
                                    state={{from: `/school/${data.school.id}`, pageTitle: "School"}}>
                                    
                                    View Invoice
                                </Link>
                            </td>
                        </tr>
                    })}
                    
                    </tbody>
                </table>
             </div>       
            }
            </div>
        );
    }

    return null;

}

export default SchoolHome;
