import React, { useEffect, useState } from 'react';
import notificationSVG from '../../images/notifications.svg';
import plusCircled from '../../images/plus-circled.svg';
import styles from '../Notifications/Notifications.module.scss';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import { NOTIFICATIONS_BY_STATUS } from '../Notifications/services/graphql';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-teacher-components';
import { navigate } from '@reach/router';

const TABS = {
    published: 1,
    archived: 2,
};

function NotificationsList(props) {
    return (
        <div className={styles.notificationsContainer}>
            {props.notifications.map((notification) => (
                <div
                    key={notification.id}
                    className={styles.notificationWrapper}
                    onClick={() =>
                        navigate(`notification/${notification.id}`, {
                            state: notification,
                        })
                    }
                >
                    <div className={styles.TagsContainer}>
                        {notification.newTag && (
                            <span className={`${styles.Tag} ${styles.New}`}>
                                New
                            </span>
                        )}
                        {notification.boomreaderTag && (
                            <span
                                className={`${styles.Tag} ${styles.BoomReader}`}
                            >
                                BoomReader
                            </span>
                        )}
                        {notification.boomwriterTag && (
                            <span
                                className={`${styles.Tag} ${styles.BoomWriter}`}
                            >
                                BoomWriter
                            </span>
                        )}
                    </div>
                    <h3>{notification.title}</h3>
                    {notification && notification.fileUrl && (
                        <img
                            className={styles.ArtWorkPreview}
                            src={notification?.fileUrl
                                ?.split('?')[0]
                                .toString()}
                            alt='notification-artwork'
                        />
                    )}

                    <p className={`${styles.MessageElipsis} u-m-top-1`}>{notification.message}</p>
                </div>
            ))}

            <a
                target='blank'
                className={`${styles.notificationWrapper} ${styles.emptyNotification}`}
                rel='noopener noreferrer'
                onClick={() => navigate(`notification/new`)}
            >
                <img width='48' src={plusCircled} alt='plus-circle-svg' />
            </a>
        </div>
    );
}

const Notifications = () => {
    const [selectedTab, setSelectedTab] = useState(TABS.published);
    const [notifications, setNotifications] = useState([]);

    const { data, loading } = useQuery(NOTIFICATIONS_BY_STATUS, {
        variables: {
            status: selectedTab,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only', // Ensure the latest data is fetched from the server
    });

    useEffect(() => {
        if (data?.notificationsByStatus.length) {
            setNotifications(data?.notificationsByStatus);
        }
    }, [data]);

    return (
        <>
            <div className={styles.Header}>
                <h2 className={styles.HeaderTitle}>Notifications</h2>
                <img width='20' src={notificationSVG} alt='notification-svg' />
            </div>

            <Tabs className={styles.TabsContainer}>
                <Tab
                    selected={selectedTab === TABS.published}
                    onClick={() => setSelectedTab(TABS.published)}
                >
                    Published
                </Tab>
                <Tab
                    selected={selectedTab === TABS.archived}
                    onClick={() => setSelectedTab(TABS.archived)}
                >
                    Archived
                </Tab>
            </Tabs>

            {loading ? (
                <Loader />
            ) : (
                <NotificationsList notifications={notifications} />
            )}
        </>
    );
};

export default Notifications;
