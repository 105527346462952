import React, { Fragment, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-teacher-components';
import { Link } from '@reach/router';
import { ADMIN_STATS } from '../../services/graphql';
import cx from 'classnames';

import styles from '../../Dashboard.module.scss';
import { ArrowRight } from 'react-feather';
import notifications from '../../../../images/notifications.svg'

const DefaultDashboard = () => {

    const [adminStats, setAdminStats] = useState([]);

    const { data, error, loading } = useQuery(ADMIN_STATS, {
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if (data && data.adminStats) {
            setAdminStats(data.adminStats)
        }
    }, [data])

    const getStats = type => {
        const item = adminStats.find(s => s.key.indexOf(type) !== -1);
        if (item) {
            return item.key.indexOf('invoices') === -1 && item.key.indexOf('orders') === -1 ? item.value : `${item.value} new`
        }
    }

    const getTrials = type => {
        const item = adminStats.find(s => s.key.indexOf(type) !== -1);
        if (item) {
            return item.value
        }
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    return (
        <Fragment>
            <h1 className={cx(styles.sectionTitle, 'u-m-top-4')}>Invoice & Order Management</h1>
            <div className={styles.section}>
                <Link to='/invoices' className={styles.sectionLink}>
                    <p className={styles.sectionTitle}>Invoices</p>
                    <div className={styles.sectionCount}>{getStats('invoices')}</div>
                </Link>
                <Link to='/orders' className={styles.sectionLink}>
                    <p className={styles.sectionTitle}>Book orders</p>
                    <div className={styles.sectionCount}>{getStats('orders')}</div>
                </Link>
                <Link to='/trials' className={styles.sectionLink}>
                    <p className={styles.sectionTitle}>Trial Schools</p>
                    <div className={styles.sectionCount}>{getTrials('trial_invoices')}</div>
                </Link>
                <Link to='/schools/wonde' className={styles.sectionLink}>
                    <p className={styles.sectionTitle}>Wonde Schools</p>
                    <div className={styles.sectionCount}><ArrowRight size={18} /></div>
                </Link>
            </div>
            <h1 className={cx(styles.sectionTitle, 'u-m-top-4')}>Utilities</h1>
            <div className={styles.section}>
                <Link to='/schools' className={styles.sectionLink}>
                    <p className={styles.sectionTitle}>Manage Schools</p>
                    <div className={styles.sectionCount}>{getStats('schools')}</div>
                </Link>
                <Link to='/packs' className={styles.sectionLink}>
                    <p className={styles.sectionTitleLarge}>Manage Book Types</p>
                </Link>
                <Link to='/books' className={styles.sectionLink}>
                    <p className={styles.sectionTitleLarge}>Books</p>
                </Link>
                <Link to='/findParent' className={styles.sectionLink}>
                    <p className={styles.sectionTitleLarge}>Find Parents &amp; Teachers</p>
                </Link>
                <Link to='/writingBees' className={styles.sectionLink}>
                    <p className={styles.sectionTitleLarge}>Writing Bees</p>
                </Link>
                <Link to='/notifications' className={styles.sectionLink}>
                    <div className={styles.LinkContainer}>
                        <p className={styles.sectionTitleLarge}>Notifications</p>
                        <img className={styles.LinkContainerIcon} width="50" src={notifications} alt='notifications-svg' />
                    </div>

                </Link>
            </div>
            <h1 className={cx(styles.sectionTitle, 'u-m-top-4')}>Usage Stats</h1>
            <div className={styles.section}>

                <div className={styles.sectionStat}>
                    <p className={styles.sectionTitle}>Parents</p>
                    <div className={styles.sectionCount}>{getStats('parents')}</div>
                </div>
                <div className={styles.sectionStat}>
                    <p className={styles.sectionTitle}>Pupils</p>
                    <div className={styles.sectionCount}>{getStats('pupils')}</div>
                </div>
                <div className={styles.sectionStat}>
                    <p className={styles.sectionTitle}>Logs</p>
                    <div className={styles.sectionCount}>{getStats('logs')}</div>
                </div>
            </div>
        </Fragment>

    )

}
export default DefaultDashboard;