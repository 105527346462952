import { gql } from 'apollo-boost';

export const GET_S3_UPLOAD_QUERY = gql`
    query s3UploadUrlFor($name: String, $fileType: String, $object: String!) {
        s3UploadUrlFor(name: $name, fileType: $fileType, object: $object) {
            name
            uploadUrl
        }
    }
`;

export const CREATE_NOTIFICATION = gql`
    mutation createNotification(
        $title: String!
        $message: String!
        $fileUrl: String
        $showArtwork: Boolean
        $newTag: Boolean
        $boomreaderTag: Boolean
        $boomwriterTag: Boolean
        $showHyperlink: Boolean
        $hyperlink: String
    ) {
        createNotification(
            title: $title
            message: $message
            fileUrl: $fileUrl
            showArtwork: $showArtwork
            newTag: $newTag
            boomreaderTag: $boomreaderTag
            boomwriterTag: $boomwriterTag
            showHyperlink: $showHyperlink
            hyperlink: $hyperlink
        ) {
            errors
        }
    }
`;

export const ARCHIVE_OR_UNANRCHIVE_NOTIFICATION = gql`
    mutation archiveOrUnarchiveNotification(
        $notificationId: ID!
        $archive: Boolean!
    ) {
        archiveOrUnarchiveNotification(
            notificationId: $notificationId
            archive: $archive
        ) {
            errors
        }
    }
`;

export const EDIT_NOTIFICATION = gql`
    mutation editNotification(
        $notificationId: ID!
        $title: String
        $message: String
        $fileUrl: String
        $showArtwork: Boolean
        $newTag: Boolean
        $boomreaderTag: Boolean
        $boomwriterTag: Boolean
        $showHyperlink: Boolean
        $hyperlink: String
    ) {
        editNotification(
            notificationId: $notificationId
            title: $title
            message: $message
            fileUrl: $fileUrl
            showArtwork: $showArtwork
            newTag: $newTag
            boomreaderTag: $boomreaderTag
            boomwriterTag: $boomwriterTag
            showHyperlink: $showHyperlink
            hyperlink: $hyperlink
        ) {
            errors
        }
    }
`;
