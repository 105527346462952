import { gql } from 'apollo-boost';

export const NOTIFICATIONS_BY_STATUS = gql`
    query notificationsByStatus($status: Int!) {
        notificationsByStatus(status: $status) {
            boomreaderTag
            boomwriterTag
            fileUrl
            hyperlink
            id
            message
            newTag
            showArtwork
            showHyperlink
            status
            title
        }
    }
`;
