import React, { useState } from 'react';
import styles from './Notification.module.scss';
import notificationSVG from '../../images/notifications.svg';
import plusCircled from '../../images/plus-circled.svg';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {
    GET_S3_UPLOAD_QUERY,
    CREATE_NOTIFICATION,
    EDIT_NOTIFICATION,
    ARCHIVE_OR_UNANRCHIVE_NOTIFICATION,
} from './services/graphql';
import { useEffectOnce } from 'react-use';
import { useLocation } from '@reach/router';
import BackLink from '../../components/BackLink';
import { navigate } from '@reach/router';

function NotificationSwitch(props) {
    return (
        <div className={styles.ToggleFormWrapper}>
            <div className='basic-form__switch'>
                <input
                    name={props.name}
                    className='switch-input'
                    type='checkbox'
                    id={props.id}
                    defaultChecked={false}
                    ref={props.register}
                />
                <label className='switch-label' htmlFor={props.id}></label>
            </div>
            <span className={styles.SwitchLabel}>{props.label}</span>
        </div>
    );
}

function NotificationHeader(props) {
    return (
        <div className={styles.Header}>
            <div className={styles.TitleIconContainer}>
                <h2 className={styles.HeaderTitle}>Notifications</h2>
                <img width='20' src={notificationSVG} alt='notification-svg' />
            </div>

            <div>
                {!props.notificationStatus && (
                    <button
                        onClick={props.publishNotification}
                        className={styles.PublishButton}
                    >
                        Publish
                    </button>
                )}

                {props.notificationStatus && (
                    <button
                        onClick={props.archiveNotificationHandler}
                        className={styles.ArchiveButton}
                    >
                        {props.notificationStatus === 1
                            ? 'Archive'
                            : 'Unarchive'}
                    </button>
                )}

                <button
                    onClick={props.editNotificationHandler}
                    className={styles.EditButton}
                >
                    Edit
                </button>
            </div>
        </div>
    );
}

function NotificationForm(props) {
    return (
        <div className={styles.NotificationForm}>
            <h2>Artwork</h2>

            <div
                className={`${styles.notificationWrapper} ${styles.emptyNotification}`}
            >
                <input
                    name='resources'
                    className={`${styles.fileUpload} basic-form__text-box`}
                    placeholder='Artwork'
                    onChange={(e) =>
                        props.onSelectFile(
                            e.target.files,
                            'notificationResources'
                        )
                    }
                    type='file'
                    accept='image/*'
                    ref={props.register}
                />

                {props.artowrkUrl ? (
                    <img
                        className={styles.ArtWorkPreview}
                        src={props.artworkPreview}
                        alt='artwork-img'
                    />
                ) : (
                    <img width='48' src={plusCircled} alt='plus-circle-svg' />
                )}
            </div>

            <form>
                <h2>Title</h2>
                <div className='basic-form__group'>
                    <input
                        name='title'
                        placeholder='Type your title for the notification here'
                        className='basic-form__text-box'
                        type='text'
                        ref={props.register}
                    />
                </div>
                {props.errors.title && (
                    <p className='basic-form__hint'>
                        {props.errors.title.message}
                    </p>
                )}

                <h2>Message</h2>
                <div className='basic-form__group'>
                    <textarea
                        name='message'
                        placeholder='Type your message for the notification here'
                        className='basic-form__text-box'
                        type='text'
                        ref={props.register}
                    />
                </div>
                {props.errors.message && (
                    <p className='basic-form__hint'>
                        {props.errors.message.message}
                    </p>
                )}

                <h2>Settings</h2>
                <div className={styles.ToggleLabelGroup}>
                    <NotificationSwitch
                        register={props.register}
                        name='newTag'
                        id='newTagId'
                        label='New Tag'
                    ></NotificationSwitch>
                    <NotificationSwitch
                        register={props.register}
                        name='showArtwork'
                        id='showArtworkId'
                        label='Show Artwork'
                    ></NotificationSwitch>
                </div>

                <div className={styles.ToggleLabelGroup}>
                    <NotificationSwitch
                        register={props.register}
                        name='boomreaderTag'
                        id='boomReaderTagId'
                        label='BoomReader Tag'
                    ></NotificationSwitch>
                    <NotificationSwitch
                        register={props.register}
                        name='boomwriterTag'
                        id='boomWriterTagId'
                        label='BoomWriter Tag'
                    ></NotificationSwitch>
                </div>

                <div
                    className={`${styles.ToggleFormWrapper} ${styles.HyperlinkWrapper}`}
                >
                    <div className='basic-form__switch'>
                        <input
                            name='showHyperlink'
                            className='switch-input u-m-right-3'
                            type='checkbox'
                            id='hyperlinkTagId'
                            defaultChecked={false}
                            ref={props.register}
                            onChange={(e) =>
                                props.setIsHyperlinkChecked(e.target.checked)
                            } // Manually track state
                        />
                        <label
                            className='switch-label'
                            htmlFor='hyperlinkTagId'
                        ></label>
                    </div>
                    <div className={styles.SwitchLabel}>
                        <div className='basic-form__group'>
                            <input
                                disabled={!props.hyperlinkChecked}
                                name='hyperlink'
                                placeholder='Hyperlink'
                                className={`${styles.SwitchLabel} basic-form__text-box`}
                                type='text'
                                ref={props.register}
                            />
                        </div>
                        {props.hyperlinkChecked && props.errors.hyperlink && (
                            <p className='basic-form__hint'>
                                {props.errors.hyperlink.message}
                            </p>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}

function NotificationPreview(props) {
    return (
        <div className={styles.NotificationPreview}>
            <a
                href={props.showHyperlink ? props.hyperlink : undefined}
                rel='noopener noreferrer'
                target='blank'
            >
                <h2>Preview</h2>

                <div className={styles.notificationWrapper}>
                    <div className={styles.TagsContainer}>
                        {props.showNewTagPreview && (
                            <span className={`${styles.Tag} ${styles.New}`}>
                                New
                            </span>
                        )}

                        {props.showboomReaderTagPreview && (
                            <span
                                className={`${styles.Tag} ${styles.BoomReader}`}
                            >
                                BoomReader
                            </span>
                        )}

                        {props.showBoomWriterTagPreview && (
                            <span
                                className={`${styles.Tag} ${styles.BoomWriter}`}
                            >
                                BoomWriter
                            </span>
                        )}
                    </div>
                    <h3>{props.titlePreview}</h3>
                    {props.showArtWorkPreview && props.artworkPreview && (
                        <img
                            className={styles.ArtWorkPreview}
                            src={props.artworkPreview}
                        />
                    )}
                    <p className={`${styles.MessageElipsis} u-m-top-1`}>
                        {props.messagePreview}
                    </p>
                </div>
            </a>
        </div>
    );
}

const Notification = () => {
    const location = useLocation();
    const client = useApolloClient();
    let notification = location?.state || undefined;
    const [hyperlinkChecked, setIsHyperlinkChecked] = useState(false);
    const [artowrkUrl, setArtworkUrl] = useState('');
    const [artworkPreview, setArtworkPreview] = useState('');

    const formValidationSchema = Yup.object().shape({
        title: Yup.string().required('Please enter number a pack name'),
        message: Yup.string().required('Please enter a pack description'),
        showArtwork: Yup.boolean(),
        newTag: Yup.boolean(),
        boomreaderTag: Yup.boolean(),
        boomwriterTag: Yup.boolean(),
        showHyperlink: Yup.boolean(),
        hyperlink: Yup.string()
            .url('Please enter a valid URL (e.g. https://<url>)')
            .when('showHyperlink', {
                is: true, // Only validate when checkbox is checked
                then: (schema) => schema.required('A hyperlink is required'),
                otherwise: (schema) => schema.notRequired(), // Not required when checkbox is unchecked
            }),
    });

    const { register, errors, handleSubmit, watch } = useForm({
        defaultValues: {
            title: notification.title || '',
            message: notification.message || '',
            showArtwork: notification.showArtwork || false,
            newTag: notification.newTag || false,
            boomreaderTag: notification.boomreaderTag || false,
            boomwriterTag: notification.boomwriterTag || false,
            showHyperlink: notification.showHyperlink || false,
            hyperlink: notification.hyperlink || undefined,
        },
        validationSchema: formValidationSchema,
        mode: 'onBlur',
    });

    useEffectOnce(() => {
        setArtworkPreview(notification?.fileUrl?.split('?')[0] || '');
        setArtworkUrl(notification?.fileUrl);
    });
    const [createNotification] = useMutation(CREATE_NOTIFICATION, {
        onCompleted: () => {
            navigate('/notifications');
        },
    });
    const [editNotification] = useMutation(EDIT_NOTIFICATION);
    const [archiveOrUnarchiveNotification] = useMutation(
        ARCHIVE_OR_UNANRCHIVE_NOTIFICATION,
        {
            onCompleted: () => {
                navigate('/notifications');
            },
        }
    );

    const handlePublishNotification = (data) => {
        createNotification({
            variables: {
                title: data.title,
                message: data.message,
                fileUrl: artowrkUrl,
                showArtwork: data.showArtwork,
                newTag: data.newTag,
                boomreaderTag: data.boomreaderTag,
                boomwriterTag: data.boomwriterTag,
                showHyperlink: data.showHyperlink,
                hyperlink: data.showHyperlink ? data.hyperlink : undefined,
            },
        });
    };

    const handleEditNotification = (data) => {
        editNotification({
            variables: {
                notificationId: notification.id,
                title: data.title,
                message: data.message,
                fileUrl: artowrkUrl,
                showArtwork: data.showArtwork,
                newTag: data.newTag,
                boomreaderTag: data.boomreaderTag,
                boomwriterTag: data.boomwriterTag,
                showHyperlink: data.showHyperlink,
                hyperlink: data.showHyperlink ? data.hyperlink : undefined,
            },
        });
    };

    const handleArchiveNotification = () => {
        archiveOrUnarchiveNotification({
            variables: {
                notificationId: notification.id,
                archive: notification.status === 1 ? true : false,
            },
        });
    };

    const publishNotificationHandler = () => {
        handleSubmit(handlePublishNotification)();
    };

    const editNotificationHandler = () => {
        handleSubmit(handleEditNotification)();
    };

    const archiveNotificationHandler = () => {
        handleSubmit(handleArchiveNotification)();
    };

    const onSelectFile = (files, objectName) => {
        if (files.length) {
            const extension = files[0].name.split('.').pop().toLowerCase();
            const filename = `${objectName}-${new Date().getTime()}.${extension}`;
            client
                .query({
                    query: GET_S3_UPLOAD_QUERY,
                    variables: {
                        name: filename,
                        fileType: extension,
                        object: objectName,
                    },
                })
                .then((r) => {
                    const options = {
                        headers: {
                            'Content-Type': '',
                            'x-amz-acl': 'public-read',
                        },
                    };
                    axios
                        .put(r.data.s3UploadUrlFor.uploadUrl, files[0], options)
                        .then((s3r) => {
                            setArtworkUrl(r.data.s3UploadUrlFor.uploadUrl);
                            setArtworkPreview(
                                r.data.s3UploadUrlFor.uploadUrl.split('?')[0]
                            );
                        });
                });
        }
    };

    const showNewTagPreview = watch('newTag');
    const showboomReaderTagPreview = watch('boomreaderTag');
    const showBoomWriterTagPreview = watch('boomwriterTag');
    const showArtWorkPreview = watch('showArtwork');
    const titlePreview = watch('title');
    const messagePreview = watch('message');
    const notificationHyperlink = watch('hyperlink');
    const showHyperlink = watch('showHyperlink');

    return (
        <>
            <BackLink to='/notifications' text='Back' />
            <NotificationHeader
                publishNotification={publishNotificationHandler}
                editNotificationHandler={editNotificationHandler}
                archiveNotificationHandler={archiveNotificationHandler}
                notificationStatus={notification?.status}
            ></NotificationHeader>
            <div className={`${styles.NotificationContainer} u-m-top-2`}>
                <NotificationForm
                    hyperlinkChecked={hyperlinkChecked}
                    setIsHyperlinkChecked={setIsHyperlinkChecked}
                    artowrkUrl={artowrkUrl}
                    artworkPreview={artworkPreview}
                    register={register}
                    errors={errors}
                    onSelectFile={onSelectFile}
                ></NotificationForm>
                <NotificationPreview
                    artworkPreview={artworkPreview}
                    showNewTagPreview={showNewTagPreview}
                    showboomReaderTagPreview={showboomReaderTagPreview}
                    showBoomWriterTagPreview={showBoomWriterTagPreview}
                    showArtWorkPreview={showArtWorkPreview}
                    titlePreview={titlePreview}
                    messagePreview={messagePreview}
                    hyperlink={notificationHyperlink}
                    showHyperlink={showHyperlink}
                ></NotificationPreview>
            </div>
        </>
    );
};

export default Notification;
