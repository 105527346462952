// School mutations and queries used in more than one place
import { gql } from 'apollo-boost';

export const GET_SCHOOLS_QUERY = gql`
query schools {
    schools {
        id
        name
        address
        address2
        city
        zip
        schoolCode
    }
}
`;

export const SEARCH_SCHOOLS = gql`
query schoolsSearch($text: String!) {
    schoolsSearch(text: $text) {
        id
        name
        financeOfficerEmail
    }
}
`;

export const GET_SCHOOL_QUERY = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        address
        city
        address2
        name
        zip
        schoolPhoneNumber
        studentsUsingGoRead
        canAccessWonde
        canAccessGoReadApp
        goReadSubscriptionExpired
        duplicates
        schoolCode
        contests {
            id
            status
            name
        }
        contestMemberships {
            id
            studentsCounter
            actualStudentsCounter
            studentsClass {
                id
                name
            }
        }
        stats {
            id
            key
            value
        }
        admins {
            active
            displayName
            email
            firstName
            id
            isSchoolAdmin
            lastName
        }
        classes {
            id
            name
            description
            archived
            teachers {
                id
                displayName
                active
            }
            classPacks {
                id
                purchasedPack {
                    id
                }
                bookTemplate {
                    id
                    name
                }
                bookTemplateCategory {
                    id
                    name
                    description
                }
            }
        }
        teachers {
            active
            displayName
            email
            firstName
            id
            isSchoolAdmin
            lastName
            expiredToken
            roleId
        }
        purchasedPacks {
            id
            instances
            maxNumberOfStudents
            studentsClasses {
                id
                name
            }
        }
        schoolSubscriptions {
            id
            academicYear
            status
            expiresAt
            isTrial
            app {
                id
                name
            }
            invoiceRequest {
                id
                studentsCounter
                orderedAt
            }
        }
        invoiceRequests {
            id
            externalId
            status
            source
            amount
            issuedAt
        }
    }
}
`;

export const ADD_TEACHER_MUTATION = gql`
mutation addTeacher($schoolId: ID!, $firstName: String!, $lastName: String!, $displayName: String!, $email: String!, $isSchoolAdmin: Boolean!, $forceActivation: Boolean, $roleId: Int!) {
    addTeacher(schoolId: $schoolId, firstName: $firstName, lastName: $lastName, displayName: $displayName, email: $email, isSchoolAdmin: $isSchoolAdmin, forceActivation: $forceActivation, roleId: $roleId) {
        teacher {
            active
            displayName
            email
            firstName
            id
            isSchoolAdmin
            lastName
            token
            expiredToken
            roleId
            school {
                id
            }
            token
        }
        errors
    }
}
`;

export const GET_ARCHIVED_CLASSES = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        archivedClasses {
            id
            name
            archived
            description
            inactiveTeachers {
                id
                displayName
                active
            }
        }
    }
}
`;

export const EDIT_SCHOOL_MUTATION = gql`
mutation editSchool($schoolId: ID!, $name: String!, $address: String!, $city: String!, $zip: String!, $address2: String!, $localAuthority: String, $country: String, $schoolPhoneNumber: String ) {
    editSchool(schoolId: $schoolId, name: $name, address: $address, city: $city, zip: $zip, address2: $address2, localAuthority: $localAuthority, country: $country, schoolPhoneNumber: $schoolPhoneNumber) {
        school {
            id
        }
        errors
    }
}
`;

export const UPDATE_SCHOOL_LOCAL_AUTHORITY = gql`
mutation updateSchoolLocalAuthority($schoolId: ID!, $localAuthority: String!) {
    updateSchoolLocalAuthority(schoolId: $schoolId, localAuthority: $localAuthority) {
        school {
            id
        }
        errors
    }
}
`;



export const UPDATE_READ_SUBSCRIPTION = gql`
mutation updateGoReadSubscription($schoolSubscriptionId: ID!, $status: Int!) {
    updateGoReadSubscription(schoolSubscriptionId: $schoolSubscriptionId, status: $status) {
        errors
        schoolSubscription {
            id
            status
        }
    }
}
`;


export const MERGE_PUPIL_DUPLICATES = gql`
mutation mergeDuplicates($schoolId: ID!) {
    mergeDuplicates(schoolId: $schoolId) {
        school {
            id
        }
        errors
    }
}
`;